<template>
  <div class="dotProgress">
    <div v-for="i in allElements" :key="i" class="dot" @click="$emit('update:currentProgress',i-1)" :class="{'active':i-1===currentProgress}">
      {{i}}
    </div>
  </div>
</template>

<script>
export default {
  name: "DotIndicator",
  props:{
    currentProgress:Number,
    allElements:Number
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/themes.scss";
  .dotProgress{
    padding:32px;
    display: flex;
    gap:8px;
  }
  .dot{
    cursor: pointer;
    height: 32px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 32px;
    @include theme {
      color: theme-get("primary-color");
      background-color: theme-get("primary-background")
    }
    &.active{
      color: #FFFFFF;
      @include theme {
        background-color: theme-get("primary-color")
      }
    }
  }
</style>