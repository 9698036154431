<template>
  <div class="addTournament">
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <h2>Turnier hinzufügen</h2>
    <div class="tournamentForm" v-if="progress===0">
      <div>
        <div class="gameSelection">
          <DropDown class="gameDD"
                    :elements="games.map(value => {return {'value':value.game_id,'description':value.full_name,'supported_sizes':value.supported_team_sizes}})"
                    :title="'Game'" v-model:activeElement="selectedGame" has-restrained-space>
          </DropDown>
          <DropDown class="teamSizeDD"
                    :elements="selectedGame?.supported_sizes?.map(value=>{return{'value':value, 'description':value}})"
                    :title="'Teamgröße'" v-model:activeElement="teamSize">
          </DropDown>
        </div>
        <div class="inputSwitch">
          <TextInput  :input-title="nameInputTitle.description" v-model:input-text="name"/>
          <div class="material-icons switch" @click="changeInputTitle">
            loop
          </div>
        </div>
        <div id="limit">
          <div class="teamLimitToggle">
            <Checkbox :is-active="teamLimitActive" :click-function="toggleTeamLimit"/>
            <p>{{teamSize.value>1?'Team':'Spieler'}} Anmeldungen limitieren</p>
          </div>
          <transition name="team-limit">
            <TextInput v-if="teamLimitActive" input-title="Team Limit" v-model:input-text="teamLimit"   :inputPlaceholder="69" :textFieldType="'number'" ></TextInput>
          </transition>
        </div>
      </div>

      <PlainButton :button-function="()=>{progress++}">Weiter</PlainButton>
    </div>
    <div class="tournamentForm" v-if="progress===1">
      <div class="additional">
          <TextInput input-title="Name-Source" v-model:input-text="ingame_source"/>
          <TextInput input-title="Preis" v-model:input-text="prize"/>
          <div class="discordToggle">
            <Checkbox :is-active="discordRequired" :click-function="toggleDiscordRequirement"/>
            <p>Discord verpflichtend</p>
          </div>
      </div>
      <div class="buttonrow">
        <PlainButton :button-function="()=>{progress--}" button-type="fluid">Zurück</PlainButton>
        <PlainButton :button-function="()=>{progress++}">Weiter</PlainButton>
      </div>

    </div>
    <div class="tournamentForm" v-if="progress===2">
      <div class="datepicker">
        <label>Startdatum</label>
        <DatePicker v-model:selected-date="date"></DatePicker>
      </div>
      <div class="datepicker">
        <label>Anmeldeschluss</label>
        <DatePicker v-model:selected-date="team_close_date"></DatePicker>
      </div>
      <div class="buttonrow">
        <PlainButton :button-function="()=>{progress--}" button-type="fluid">Zurück</PlainButton>
        <PlainButton :button-function="()=>{progress++}">Weiter</PlainButton>
      </div>
    </div>
    <div class="tournamentForm" v-if="progress===3">
      <div class="datepicker">
        <label>Description</label>
        <MarkdownEditor v-model:markdown-text="description"></MarkdownEditor>
      </div>
      <div class="buttonrow">
        <PlainButton :button-function="()=>{progress--}" button-type="fluid">Zurück</PlainButton>
        <PlainButton :button-function="()=>{progress++}">Weiter</PlainButton>
      </div>
    </div>
    <div class="tournamentSummary" v-if="progress===4">
      <div class="summaryContent ">
        <div class="gameSelection summaryGames" >
          <div class="tournamentInfo gameDD">
            <h4>Spiel</h4>
            <div>{{selectedGame.description}}</div>
          </div>
          <div class="tournamentInfo teamSizeDD">
            <h4>Teamgröße</h4>
            <div>{{teamSize.value}}</div>
          </div>
        </div>
        <div v-if="markdownDescription.trim().length>0" class="tournamentInfo summaryDescription">
          <h4>Description</h4>
          <div v-html="markdownDescription" class="markdown">
          </div>
        </div>
        <div class="summaryDetails">
          <div v-if="name.length>0" class="tournamentInfo">
            <h4>{{this.nameInputTitle.description}}</h4>
            <div>{{name}}</div>
          </div>
          <div v-if="prize.length>0" class="tournamentInfo">
            <h4>Preis</h4>
            <div>{{prize}}</div>
          </div>
          <div class="tournamentInfo">
            <h4>Startdatum</h4>
            <div>{{formattedDate}}</div>
          </div>
          <div class="tournamentInfo">
            <h4>Anmeldeschluss</h4>
            <div>{{formattedCloseDate}}</div>
          </div>
          <div v-if="ingame_source.length>0" class="tournamentInfo">
            <h4>Ingame Name</h4>
            <div>{{ingame_source}}</div>
          </div>
          <div class="tournamentInfo" v-if="teamLimitActive">
            <h4>Team Limit</h4>
            <div>{{teamLimit}}</div>
          </div>
        </div>
      </div>
      <div class="buttonrow">
        <PlainButton :button-function="()=>{progress--}" button-type="fluid">Zurück</PlainButton>
        <PlainButton :button-function="()=>{createNewTournament()}">Bestätigen</PlainButton>
      </div>

    </div>
    <DotIndicator v-model:currentProgress="progress" :all-elements="5"></DotIndicator>
  </div>

  <transition name="error-show">
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'">
    </ErrorPop>
  </transition>

</template>

<script>
import {mapActions, mapState} from "vuex";
import {adminService} from "@/services";
import DropDown from "@/components/Inputs/DropDown";
import TextInput from "@/components/Inputs/TextInput";
import PlainButton from "@/components/Buttons/PlainButton";
import DatePicker from '@/components/Inputs/DatePicker';
import MarkdownEditor from "@/components/Inputs/MarkdownEditor";
import {marked} from 'marked';
import ErrorPop from "@/components/Popups/ErrorPop";
import {formatDateTime} from "@/helpers";
import DotIndicator from "@/components/Indicators/DotIndicator";
import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";
import Checkbox from "@/components/Inputs/Checkbox.vue";
let inputTitles=[
  {
    'id':0,
    'isSuffix':true,
    'description':'Turnier-Suffix',
    'apiName':'title_suffix'
  },
  {
    'id':1,
    'isSuffix':false,
    'description':'Ganzer Name',
    'apiName':'title'
  },
]

export default {
  name: "AddTournament",
  components: {
    Checkbox,
    ErrorPop, DotIndicator, MarkdownEditor, DatePicker, PlainButton, TextInput, DropDown, FullscreenLoading},
  data() {
    return {
      selectedGame: {},
      teamSize: {},
      games: [],
      description:"",
      name: "",
      prize: "",
      testNumber: 0,
      ingame_source: "",
      progress: 0,
      date: new Date(),
      team_close_date: new Date(),
      nameInputTitle:inputTitles[0],
      teamLimitActive:false,
      teamLimit:0,
      discordRequired:false
    }
  },
  created() {
    this.fetchGames()
  },

  computed: {
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    }),
    formattedDate(){
      return formatDateTime(this.date)
    },
    formattedCloseDate(){
      return formatDateTime(this.team_close_date);
    },
    tournament() {
      return {
        'title_suffix': this.nameInputTitle.isSuffix?(this.name.length>0?this.name:null):null,
        'title': !this.nameInputTitle.isSuffix?(this.name.length>0?this.name:null):null,
        'game_id': this.selectedGame.value,
        'team_size': this.teamSize.value,
        'datetime': this.date.toJSON(),
        'team_registration_closed': this.team_close_date.toJSON(),
        'description':this.description.length>0?this.description:null,
        'prize': this.prize.length>0?this.prize:null,
        'event_id': this.$route.params.event_id,
        'ingame_name_source': this.ingame_source.length>0?this.ingame_source:null,
        'max_teams':this.teamLimitActive?Number(this.teamLimit):null,
        'discord_required': this.discordRequired
      }
    },
    markdownDescription(){
      return marked(this.description)
    }
  },
  methods: {
    changeInputTitle(){
      this.name=''
      if(this.nameInputTitle.id===0)
        this.nameInputTitle=inputTitles[1]
      else
        this.nameInputTitle=inputTitles[0]
    },
    ...mapActions("alert", ['success', "error"]),
    createNewTournament() {
      adminService.addTournament(this.tournament).then(
          event => {
            this.success('Game Added: ' + event.event_name);
            this.$router.back()
          },
          error => {
            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchGames() {
      adminService.fetchGames().then(games => {
        this.games = games
        this.selectedGame = {
          'value': this.games[0].game_id,
          'description': this.games[0].full_name,
          'supported_sizes': this.games[0].supported_team_sizes
        }
        this.teamSize = {
          'value': this.selectedGame.supported_sizes[0],
          'description': this.selectedGame.supported_sizes[0]
        }
      })
    },
    toggleTeamLimit(){
      this.teamLimitActive=!this.teamLimitActive
    },
    toggleDiscordRequirement(){
      this.discordRequired=!this.discordRequired
    }

  },
  watch: {
    selectedGame() {
      this.teamSize = {
        'value': this.selectedGame.supported_sizes[0],
        'description': this.selectedGame.supported_sizes[0]
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../styles/themes.scss";

#limit{
  width: 100%;
}

.teamLimitToggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  p{
    margin:0;
  }
  font-weight: 500;
  @include theme() {
    color: theme-get('header-3')
  }

  font-size: 1.25em;

}

.team-limit-enter-active, .team-limit-leave-active {
  transition: 0.25s;
}

.team-limit-enter-from, .team-limit-leave-to {
  transform: scale(0);
  opacity: 0;
}

h2 {
    font-weight: 600;
    text-align: center;
    font-size: 3em;
    @include theme() {
      color: theme-get('header-1')
    }
    margin-bottom: 32px;
  }
label {
  text-align: left;
  align-self: flex-start;
  transition: 0.25s;
  font-weight: 500;
  font-size: 1.5em;
  font-family: 'Poppins', sans-serif;
  @include theme() {
    color: theme-get('text-1')
  }

  &.focused {
    @include theme() {
      color: theme-get('primary-color')
    }
  }
}

.discordToggle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  p{
    margin:0;
  }
  font-weight: 500;
  @include theme() {
    color: theme-get('header-3')
  }

  font-size: 1.25em;
}



.addTournament {

    margin: 96px 172px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.inputSwitch{
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  .switch{
    cursor: pointer;
    padding: 8px;
    font-size: 32px;
    @include theme() {
      color: theme-get('text-1');
    }
  }
}
  .tournamentForm {
    width: 500px;
    display: flex;

    flex-direction: column;
    align-items: stretch;
    gap: 96px;
    justify-content: space-between;
    .additional{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
    }
  }

  .gameSelection {
    display: flex;
    flex-direction: row !important;
    justify-content: stretch;
    gap: 16px;
    margin-bottom: 32px;
  }

  .gameDD {
    flex: 4;
  }

  .teamSizeDD {
    flex: 1;
  }

  .buttonrow {
    gap: 12px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
  }
  .datepicker{
    text-align: left;
  }
  .tournamentSummary{
    width: 500px;
    display: flex;
    flex-direction: column;
    gap:64px;

  }
  .summaryContent{

    display: flex;
    flex-direction: column;
    gap:16px;
    div{
      margin:0 !important;
    }
  }
  .tournamentInfo{
    text-align: left;

    h4{
      margin:0;
      font-weight: 500;
      font-size: 1.5em;
      @include theme() {
        color: theme-get('header-3');

      }
    }
    div{
      font-size: 1.125em;
      @include theme() {
        color: theme-get('text-2');
      }
    }
  }
  .summaryDetails{
    @include theme {
      color: theme-get('text-2');
      background-color: theme-get('input-background');
      box-shadow: theme-get('shadow-1');
    }
    padding:16px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap:16px;
  }
  .summaryGames{
    @include theme {
      color: theme-get('text-2');
      background-color: theme-get('input-background');
      box-shadow: theme-get('shadow-1');
    }
    padding:16px;
    border-radius: 8px;
  }

  .summaryDescription{
    @include theme {
      color: theme-get('text-2');
      background-color: theme-get('input-background');
      box-shadow: theme-get('shadow-1');
    }
    padding:16px;
    border-radius: 8px;
  }

  @media screen and(max-width: 768px) {
    .addTournament{
      h2{
        font-size: 2em;
      }
      margin: 32px 32px;
      .tournamentForm{
        width: 356px;
      }
      .buttonrow{
        flex-direction: column-reverse;
      }
      .tournamentSummary{
        width: 356px;
      }
    }


  }
@media screen and(max-width: 512px) {
  .addTournament {
    h2 {
      font-size: 2em;
    }

    margin: 32px 32px;

    .tournamentForm {
      width: 312px;
    }

    .buttonrow {
      flex-direction: column-reverse;
    }

    .tournamentSummary {
      width: 312px;
    }
  }
}

</style>
